import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import {
  ViewComponent,
  ViewConfig,
  ViewSlotDirective,
  viewComponentInputs,
  viewRegistry,
  DataReferenceConfig,
  DataReferencePipe,
} from '@npm-libs/ng-templater';

const name = 'status-color';
type Name = typeof name;

export type StatusColorViewProps = {
  color?: DataReferenceConfig;
};
export type StatusColorViewConfig = ViewConfig<Name, StatusColorViewProps>;

@Component({
  standalone: true,
  selector: `rcg-templater-view--${name}`,
  // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
  inputs: [...viewComponentInputs],
  imports: [CommonModule, ViewSlotDirective, DataReferencePipe],
  templateUrl: './status-color.view.component.html',
  styleUrls: ['./status-color.view.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class StatusColorViewComponent extends ViewComponent<
  Name,
  StatusColorViewProps,
  StatusColorViewConfig
> {}

viewRegistry[name] = {
  component: StatusColorViewComponent,
};
